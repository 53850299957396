<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <span class="active">Estadísticas</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Estadísticas</h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row justify-content-between">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b-list-group flush>
            <b-list-group-item
              v-for="item in items"
              :key="item.id"
              :to="'/estadistica/' + item.url"
              class="estadisticas p-3"
              :style="{
                '--background-color-tertiary':
                  config.disenio.datos.color_terciario,
                '--color-secondary': config.disenio.datos.color_secundario,
              }"
            >
              <h5 class="mb-1">
                {{ item.nombre }}
              </h5>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import TituloSeccion from "../secciones/TituloSeccion.vue";
// import VueSocialSharing from 'vue-social-sharing'

import { BListGroup, BListGroupItem } from "bootstrap-vue";

export default {
  components: {
    TituloSeccion,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      items: [],
      tipoEntradaId: 5,
    };
  },
  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Estadisticas",
    htmlAttrs: {
      lang: "es-ES",
    },
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {
      var url = "/web/estadisticas?tipoEntradaId=" + this.tipoEntradaId;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    config() {
      return this.$store.state.config;
    },
  },
};
</script>

<style>
.justify-content-between {
  width: 100% !important;
}
.estadisticas {
  width: 100%;
  background-color: var(--background-color-tertiary);
  color: var(--color-secondary);
}
.estadisticas:hover {
  background-color: var(--color-secondary);
  color: #fff;
}
</style>
